





























































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import Intro from '@/components/Intro.vue';
import {
  fetchFlipstarterData, RUNNING, SUCCESS, EXPIRED,
} from '@/flipstarter';
import {
  Campaign,
  getCategories,
  submitFlipstarter,
} from '@/flipstarterdirectory';
import { ensureHttp, scrollBottom } from '@/utils';

const EMPTY_CAMPAIGN: Campaign = {
  amount: 0,
  url: '',
  title: '',
  description: '',
  status: RUNNING,
  fundedTx: '',
  categories: [],
  announcements: [],
  // DEPRECATED: We take our own screenshots
  archives: [],
};

@Component({
  components: {
    Intro,
  },
})
export default class Submit extends Vue {
  SUCCESS = SUCCESS;

  loadingData = false;

  dataLoaded = false;

  submitting = false;

  submitFailed = false;

  submitFailedMessage = '';

  preSubmitErrorMessages: string[] = [];

  submitSuccess = false;

  campaign = { ...EMPTY_CAMPAIGN };

  categoryOptions: string[] = [];

  categories: string[] = [];

  // applies to announcements and archives
  maxUrls = 3;

  announcement = '';

  announcements: string[] = [];

  loadCategoriesError = '';

  urlLoadingError = '';

  get announcementLimit() {
    return this.announcements.length >= this.maxUrls;
  }

  archive = '';

  archives: string[] = [];

  get archiveLimit() {
    return this.archives.length >= this.maxUrls;
  }

  statusOptions = [
    { value: SUCCESS, text: 'Success' },
    { value: RUNNING, text: 'Running' },
    { value: EXPIRED, text: 'Expired' },
  ]

  get amountBCH() {
    return this.campaign.amount / 100000000;
  }

  set amountBCH(value) {
    this.campaign.amount = value * 100000000;
  }

  @Watch('categories')
  setCampaignCategories(newValue: string[]) {
    this.campaign.categories = newValue.map((c) => {
      const obj: { name: string } = { name: c };
      return obj;
    });
  }

  @Watch('announcements')
  setCampaignAnnouncements(newValue: string[]) {
    this.campaign.announcements = newValue.map((a) => {
      const obj: { url: string } = { url: a };
      return obj;
    });
  }

  @Watch('archives')
  setCampaignArchives(newValue: string[]) {
    this.campaign.archives = newValue.map((a) => {
      const obj: { url: string } = { url: a };
      return obj;
    });
  }

  enteredUrl() {
    this.urlLoadingError = '';
    if (this.loadingData || this.campaign.url === '') {
      this.urlLoadingError = 'URL missing';
      return;
    }
    this.loadingData = true;
    fetchFlipstarterData(this.campaign.url).then((data) => {
      if (data && Object.keys(data).length > 0) {
        this.campaign = data;
      }
      this.urlLoadingError = '';
    }).catch((error) => {
      this.urlLoadingError = error.message;
    }).then(() => {
      this.dataLoaded = true;
      this.loadingData = false;
    });
    getCategories().then((categories) => {
      if (Array.isArray(categories)) {
        this.categoryOptions = categories.map((c) => c.name);
      }
      this.loadCategoriesError = '';
    }).catch((error) => {
      this.loadCategoriesError = error.message;
    });
  }

  isValidSubmission(): boolean {
    this.preSubmitErrorMessages = [];
    if (this.campaign.amount === 0) {
      this.preSubmitErrorMessages.push(
        'Campaign amount must be bigger than 0 or it isn\'t really a Flipstarter is it?',
      );
    }
    if (!this.campaign.title) {
      this.preSubmitErrorMessages.push(
        'Please add a title.',
      );
    }
    if (!this.campaign.description) {
      this.preSubmitErrorMessages.push(
        'Please add a description.',
      );
    }
    if (this.announcements.length === 0) {
      this.preSubmitErrorMessages.push(
        'Please provide at least one announcement url.',
      );
    }
    if (this.preSubmitErrorMessages.length > 0) {
      return false;
    }
    return true;
  }

  submitFlipstarter() {
    this.submitting = true;
    if (!this.isValidSubmission()) {
      this.submitting = false;
      return;
    }
    // make sure we have http or https as flipstarterdirectory
    // requires this.
    this.campaign.url = ensureHttp(this.campaign.url);
    submitFlipstarter(this.campaign).then(() => {
      this.submitSuccess = true;
      this.submitFailedMessage = '';
    }).catch((error) => {
      this.submitFailed = true;
      if (error.response.data) {
        this.submitFailedMessage = error.response.data;
      } else {
        this.submitFailedMessage = error;
      }
      this.$nextTick(() => {
        scrollBottom();
      });
    }).then(() => {
      this.submitting = false;
    });
  }

  announcementAdded(value: string) {
    this.announcements.push(value);
    this.announcement = '';
  }

  archiveAdded(value: string) {
    this.archives.push(value);
    this.archive = '';
  }

  removeAnnouncement(index: number) {
    this.announcements.splice(index, 1);
  }

  removeArchive(index: number) {
    this.archives.splice(index, 1);
  }

  reset() {
    this.submitting = false;
    this.submitFailed = false;
    this.submitSuccess = false;
    this.loadingData = false;
    this.dataLoaded = false;
    this.campaign = { ...EMPTY_CAMPAIGN };
    this.categories = [];
    this.announcements = [];
    this.archives = [];
  }
}
